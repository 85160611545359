import { reqLogin } from '@/api/index'
//home模块的小仓库
const state = {
  user: {}
};
const mutations = {
  LOGINUSER(state, user) {
    state.user = user;
  }
};
const actions = {
  //通过api里面的接口函数调用，向服务器发请求，获取服务器数据
  async userLogin({ commit }, data) {
    let result = await reqLogin(data);
    if (result.code == 0) {
      commit('LOGINUSER', result.data);
    }
  },
};
//计算属性
const getters = {};
export default {
  state, mutations, actions, getters
}