<template>
  <div class="movie-details">
    <!-- 第一部分：电影属性和简介 -->
    <div class="section">
      <div class="movie-content">
        <div class="movie-poster">
          <img :src="movieDetail.image" :alt="movieDetail.title" />
        </div>
        <div class="movie-info">
          <h1>{{ movieDetail.name }}</h1>
          <p v-if="movieDetail.byname">
            <strong>别名:</strong> {{ movieDetail.byname.join(", ") }}
          </p>
          <p v-if="movieDetail.director">
            <strong>导演:</strong> {{ movieDetail.director.join(", ") }}
          </p>
          <p v-if="movieDetail.scriptwriter">
            <strong>编剧:</strong> {{ movieDetail.scriptwriter.join(", ") }}
          </p>
          <p v-if="movieDetail.performer">
            <strong>主演:</strong> {{ movieDetail.performer.join(", ") }}
          </p>
          <p v-if="movieDetail.releaseTime">
            <strong>上映日期:</strong> {{ movieDetail.releaseTime }}
          </p>
          <p v-if="movieDetail.area">
            <strong>地区:</strong> {{ movieDetail.area }}
          </p>
          <p v-if="movieDetail.language">
            <strong>语种:</strong> {{ movieDetail.language }}
          </p>
          <p v-if="movieDetail.duration">
            <strong>时长:</strong>{{ movieDetail.duration }}
          </p>
          <p v-if="movieDetail.title">
            <strong>概述:</strong> {{ movieDetail.title }}
          </p>
        </div>
      </div>
    </div>

    <!-- 第二部分：电影下载链接 -->
    <div class="section downloadUrl">
      <h2>下载链接</h2>
      <p v-if="movieDetail.magnetismUrl">
        <strong>磁力链接:</strong>{{ movieDetail.magnetismUrl }}
      </p>
      <p v-if="movieDetail.emuleUrl">
        <strong>电驴链接:</strong>{{ movieDetail.emuleUrl }}
      </p>
    </div>

    <!-- 第三部分：电影宣传图 -->
    <div class="section">
      <h2>宣传图</h2>
      <div class="movie-images"></div>
    </div>

    <!-- 第四部分：用户评价 -->
    <div class="section">
      <h2>用户评价</h2>
      <!-- 显示用户评价 -->
      <!-- <div v-for="(review, index) in movie.reviews" :key="index" class="review">
        <div class="user-info">
          <img
            :src="review.userAvatar"
            :alt="review.userName"
            class="user-avatar"
          />
          <span class="user-name">{{ review.userName }}</span>
        </div>
        <p class="review-content">{{ review.content }}</p>
      </div> -->

      <!-- 用户添加评价的功能 -->
      <div class="add-review">
        <h3>添加评价</h3>
        <form @submit.prevent="submitReview">
          <textarea
            v-model="newReview"
            placeholder="在这里添加您的评价"
          ></textarea>
          <button type="submit">提交评价</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "MovieDetails",
  data() {
    return {
      newReview: "功能正在开发中，莫急躁", // 用户添加的新评价
    };
  },
  mounted() {
    this.$store.dispatch("movieDetailsById", this.$route.params.movieId);
  },
  computed: {
    ...mapState({
      movieDetail: (state) => state.details.movieDetail,
    }),
  },
  methods: {
    submitReview() {
      // 提交用户评价逻辑...
      const newReviewData = {
        userName: "用户名称",
        userAvatar: "用户头像链接",
        content: this.newReview,
      };
      this.movie.reviews.push(newReviewData);
      this.newReview = ""; // 清空输入框
    },
  },
};
</script>

<style scoped>
/* 整体页面样式 */
.movie-details {
  padding: 20px;
  background-color: #333; /* 修改背景颜色 */
  color: #fff; /* 修改字体颜色 */
}
/* 每个部分的样式 */
.section {
  margin-bottom: 30px;
}

/* movie-content 是用来容纳并列的盒子 */
.movie-content {
  display: flex;
  gap: 20px;
}

/* 电影海报部分样式 */
.movie-poster img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* 电影信息部分样式 */
.movie-info {
  flex-grow: 1; /* 让电影信息部分占据剩余的空间 */
}

.movie-info h1 {
  font-size: 28px;
  margin-bottom: 10px;
}

.movie-info p {
  font-size: 16px;
  margin: 5px 0;
}

.movie-info h2 {
  font-size: 24px;
  margin-top: 20px;
}

/* 电影下载链接部分样式 */
.downloadUrl p {
  font-size: 12px;
  margin: 5px 0;
}

.downloadUrl h2 {
  font-size: 24px;
  margin-top: 20px;
}

/* 电影宣传图部分样式 */
.movie-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
}

.movie-images img {
  max-width: 100%;
  border-radius: 10px;
}

/* 用户评价部分样式 */
.review {
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  font-weight: bold;
  margin-right: 5px;
}

.review-content {
  flex-grow: 1;
}

.add-review {
  margin-top: 20px;
}

.add-review h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.add-review textarea {
  width: 100%;
  height: 100px;
  resize: none;
  padding: 10px;
}

.add-review button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-review button:hover {
  background-color: #0056b3;
}
</style>