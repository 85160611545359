<template>
  <div class="search-results">
    <!-- 第一个盒子：显示搜索信息 -->
    <div class="search-info">
      <h2 class="search-title">搜索结果</h2>
      <p>
        <strong class="keyword">“{{ this.$route.params.movieName }}”</strong>
        共找到
        <span class="result-count">{{ searchResults.length }}</span>
        条相关记录！
      </p>
    </div>

    <!-- 第二个盒子：显示具体结果列表 -->
    <div class="results-list">
      <div
        v-for="(movieDetail, index) in searchResults"
        :key="movieDetail.id"
        class="result-item"
      >
        <router-link :to="`/details/${movieDetail.id}`">
          <div class="result-content">
            <div class="result-poster">
              <img :src="movieDetail.image" :alt="movieDetail.title" />
            </div>
            <div class="result-info">
              <h1>{{ movieDetail.name }}</h1>
              <p v-if="movieDetail.byname">
                <strong>别名:</strong> {{ movieDetail.byname.join(", ") }}
              </p>
              <p v-if="movieDetail.director">
                <strong>导演:</strong> {{ movieDetail.director.join(", ") }}
              </p>
              <p v-if="movieDetail.scriptwriter">
                <strong>编剧:</strong> {{ movieDetail.scriptwriter.join(", ") }}
              </p>
              <p v-if="movieDetail.performer">
                <strong>主演:</strong> {{ movieDetail.performer.join(", ") }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SearchResults",
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("searchMovieList", this.$route.params.movieName);
  },
  computed: {
    ...mapState({
      searchResults: (state) => state.search.searchResults,
    }),
  },
  watch: {
    $route: {
      handler(newValue, oldValue) {
        this.$router.go(0);
      },
    },
  },
};
</script>

<style scoped>
/* 整体页面样式 */
.search-results {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #333; /* 页面背景颜色 */
  color: #fff; /* 字体颜色 */
  align-items: stretch; /* 使两个大盒子的高度一致 */
}

/* 搜索信息样式 */
.search-info {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.search-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #ff9900; /* 橘色 */
}

.keyword {
  color: #ff0000; /* 红色 */
}

.result-count {
  color: #ff0000; /* 红色 */
}

/* 结果列表样式 */
.results-list {
  display: grid; /* 使用 Grid 布局 */
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* 自适应列布局 */
  gap: 20px;
}

.result-item {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  background-color: #444; /* 结果项背景颜色 */
}

.result-content {
  display: flex;
  align-items: flex-start; /* 保持内容在顶部对齐 */
}

.result-poster {
  flex: 0 0 40%; /* 占据 40% 的宽度 */
  margin-right: 20px; /* 间距 */
}

.result-poster img {
  max-height: 200px; /* 设置最大高度 */
  width: 100%; /* 图片宽度占满容器 */
  object-fit: cover; /* 保持图片比例并填充容器 */
  border-radius: 10px;
}

.result-info {
  flex: 1; /* 占据剩余宽度 */
}
</style>
