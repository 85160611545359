import { reqMovieList } from '@/api/index'
//home模块的小仓库
const state = {
    movieList: []
};
const mutations = {
    MOVIELIST(state, movieList) {
        state.movieList = movieList;
    }
};
const actions = {
    //通过api里面的接口函数调用，向服务器发请求，获取服务器数据
    async movieList({ commit }) {
        let result = await reqMovieList();
        if (result.code == 0) {
            commit('MOVIELIST', result.data)
        }
    }
};
//计算属性
const getters = {};
export default {
    state, mutations, actions, getters
}