<template>
  <div>
    <my-header></my-header>
    <router-view></router-view>
    <my-floor></my-floor>
  </div>
</template>

<script>
import Header from "./components/Header";
import Floor from "./components/Footer";

export default {
  name: "App",
  components: {
    "my-header": Header,
    "my-floor": Floor,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
