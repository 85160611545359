<template>
  <div class="register">
    <h2 class="register-title">用户注册</h2>
    <!-- 新增表单容器 -->
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="用户名" prop="userName">
        <el-input v-model="ruleForm.userName"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobilePhone">
        <el-input v-model="ruleForm.mobilePhone">
          <template #append>
            <el-button :disabled="isSendingCode" @click="sendCode">
              {{ isSendingCode ? `${countdown}s` : "发送验证码" }}
            </el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="code">
        <el-input v-model.number="ruleForm.code"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="passWord">
        <el-input
          type="password"
          v-model="ruleForm.passWord"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPassWord">
        <el-input
          type="password"
          v-model="ruleForm.checkPassWord"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-select v-model="ruleForm.gender" placeholder="请选择您的性别">
          <el-option label="男" value="1"></el-option>
          <el-option label="女" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年龄" required prop="age">
        <el-input v-model.number="ruleForm.age"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >免费注册</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: "register",
  data() {
    const validatePhone = (rule, value, callback) => {
      const regExp =
        /(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/;
      if (!regExp.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    var validateCode = (rule, value, callback) => {
      if (!Number.isInteger(value)) {
        callback(new Error("请输入数字值"));
      } else if (value < 100000 || value > 999999) {
        callback(new Error("请输入有效验证码"));
      } else {
        callback();
      }
    };
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("年龄不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (value < 0) {
            callback(new Error("亲，输入一个真实年龄吧"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPassWord !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.passWord) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        userName: "",
        mobilePhone: "",
        code: "",
        passWord: "",
        checkPassWord: "",
        gender: "",
        age: "",
      },
      isSendingCode: false,
      countdown: 60,
      rules: {
        userName: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 3, message: "长度最少在3个字符", trigger: "blur" },
        ],
        mobilePhone: [
          { required: true, validator: validatePhone, trigger: "blur" },
        ],
        code: [{ required: true, validator: validateCode, trigger: "blur" }],
        passWord: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        checkPassWord: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        age: [{ required: true, validator: checkAge, trigger: "blur" }],
        gender: [
          { required: true, message: "请选择您的性别", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch("register", this.ruleForm);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    async sendCode() {
      // 模拟发送验证码逻辑
      // 此处可以替换为实际发送验证码的接口调用
      if (this.isSendingCode) return; // 防止重复点击
      try {
        // 发送验证码前先禁用按钮
        this.isSendingCode = true;

        // 调用后端发送验证码的接口
        const response = await this.$store.dispatch(
          "mobileCode",
          this.ruleForm.mobilePhone
        );

        // 验证码发送成功，启动冷却计时器
        this.countdown = 60;
        const timer = setInterval(() => {
          this.countdown--;
          if (this.countdown <= 0) {
            clearInterval(timer);
            this.isSendingCode = false;
          }
        }, 1000);

        // 在实际应用中，您可能还需要处理验证码发送失败的情况，例如提示用户重试等
      } catch (error) {
        console.error("发送验证码失败:", error);
        // 在实际应用中，您可能还需要处理验证码发送失败的情况，例如提示用户重试等
        this.isSendingCode = false;
      }
    },
  },
};
</script>

<style scoped>
.register {
  max-width: 600px;
  margin: 0 auto;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
}

.register-title {
  text-align: center;
  margin: 0;
  color: #333;
  font-size: 24px;
  font-weight: bold;
  flex: 1; /* 让标题填充满上半部分 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-ruleForm .el-form-item {
  margin-bottom: 20px;
}

.custom-select {
  /* 去掉外部盒子样式 */
  display: inline-block;
  position: relative;
  width: 100%;
}

.custom-select .el-select {
  /* 还原内部下拉框样式 */
  width: 100%;
  margin: 0;
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.el-input__inner {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.el-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.el-button:hover {
  background-color: #0056b3;
}

.error {
  color: #f00;
  margin-top: 10px;
}
</style>
