<template>
  <div class="login">
    <h2>用户登录</h2>
    <el-form
      :model="loginForm"
      status-icon
      :rules="rules"
      ref="loginForm"
      label-width="100px"
      class="demo-loginForm"
    >
      <el-form-item label="手机号" prop="mobilePhone">
        <el-input v-model="loginForm.mobilePhone"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="passWord">
        <el-input
          type="password"
          v-model="loginForm.passWord"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="login('loginForm')">登录</el-button>
        <el-button @click="resetForm('loginForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    const validatePhone = (rule, value, callback) => {
      const regExp =
        /(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/;
      if (!regExp.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        mobilePhone: "",
        passWord: "",
      },
      rules: {
        mobilePhone: [
          { required: true, validator: validatePhone, trigger: "blur" },
        ],
        passWord: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
      },
      errorMessage: "",
      user: {},
    };
  },
  methods: {
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 在这里实现用户登录逻辑，与后端进行交互
          // 省略实现，仅作为示例
          if (this.loginUser()) {
            this.errorMessage = "";
            // 登录成功，跳转到用户首页或其他需要登录后访问的页面
            this.$router.push("/home");
          } else {
            this.errorMessage = "用户名或密码错误";
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async loginUser() {
      let result = await this.$API.reqLogin(this.loginForm);
      if (result.code == 0) {
        this.user = result.data;
        localStorage.setItem("medusaUser", JSON.stringify(this.user));
        return true;
      }
      return false;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.login {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 1);
  /* 添加背景颜色 */
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%; /* 让表单宽度充满登录框 */
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.error {
  color: #f00;
  margin-top: 10px;
}
</style>
