import Vue from 'vue'
import Vuex from 'vuex'
//需要使用一次
Vue.use(Vuex);
//引入小仓库
import home from './home';
import search from './search';
import register from './register';
import login from './login';
import details from './details';
//对外暴露Store类的一个实例
export default new Vuex.Store({
    //实现Vuex仓库模块化开发存储数据
    modules: {
        home,
        search,
        register,
        login,
        details
    }
})