<template>
  <div class="header">
    <div class="logo">
      <img src="./images/logo.png" alt="电影资源分享" />
    </div>
    <nav>
      <ul>
        <li>
          <a href="#">主页</a>
        </li>
        <li>
          <a href="#">分类</a>
        </li>
        <li>
          <a href="#">最新上映</a>
        </li>
        <li>
          <a href="#">热门电影</a>
        </li>
        <li>
          <a href="#">推荐</a>
        </li>
      </ul>
    </nav>
    <div class="search">
      <input type="text" v-model="keyword" />
      <router-link :to="`/search/${this.keyword}`">搜索</router-link>
      <!-- <button @click="searchMovie"></button> -->
    </div>
    <div class="user-info">
      <img v-show="this.user.userId" :src="this.user.imgUrl" alt="用户头像" />
      <router-link v-show="!this.user.userId" to="/login">登录</router-link>
      <router-link v-show="!this.user.userId" class="register" to="/register"
        >免费注册</router-link
      >
      <button v-if="this.user.userId" @click="logout">登出</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  mounted() {
    this.user = JSON.parse(localStorage.getItem("medusaUser")) || {};
  },
  data() {
    return {
      user: {},
      keyword: "搜索...",
    };
  },
  methods: {
    logout() {
      this.user = {};
      localStorage.clear("medusaUser");
    },
    searchMovie() {
      this.$router.push(`/search/${this.keyword}`);
    },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

/* 设置头部样式 */
.header {
  background-color: #333;
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  height: 40px;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav li {
  margin-right: 20px;
}

nav a {
  color: #fff;
  text-decoration: none;
}

.search input {
  padding: 5px;
}

.search button {
  padding: 5px 10px;
  background-color: #fff;
  border: none;
  color: #333;
  cursor: pointer;
}

.user-info img {
  height: 40px;
  border-radius: 50%;
}

.user-info button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 5px;
}

.user-info button:hover {
  background-color: #0056b3;
}

.register {
  border-left: 1px solid #b3aeae;
  padding: 0 5px;
  margin-left: 5px;
}
</style>