//配置路由的地方
import Vue from 'vue';
import VueRouter from 'vue-router';
//使用插件
Vue.use(VueRouter);
//引入路由组件
import Home from '@/pages/Home'
import Search from '@/pages/Search'
import Login from '@/pages/Login'
import Register from '@/pages/Register'
import Details from '@/pages/Details'
//先把VueRouter原型对象的push，先保存一份
let originPush = VueRouter.prototype.push;

//重写push|replace
//重写第一个参数 ：告诉原来的push方法，往哪里跳转（传递哪些参数）
VueRouter.prototype.push = function (localtion) {
    originPush.call(this, localtion).catch(err => { });
}
VueRouter.prototype.replace = function (localtion) {
    originPush.call(this, localtion).catch(err => { });
}

//配置路由
export default new VueRouter({
    mode: 'hash',
    //配置路由
    routes: [
        {
            path: '/home',
            component: Home,
        },
        {
            path: '/search/:movieName',
            component: Search,
            name: 'search',
            props: true
        },
        {
            path: '/login',
            component: Login,
        },
        {
            path: '/register',
            component: Register,
        },
        {
            path: '/details/:movieId',
            component: Details,
        },
        // 重定向，在项目跑起来的时候，访问/，立马让它定向到首页
        {
            path: '*',
            redirect: "/home"
        },
    ]
})