//当前这个模块：API进行统一管理
import api from "./api";
///api/product/getBaseCategoryList  get  无参数
//发请求:axios发请求返回结果Promise对象

//获取首页电影列表
export const reqMovieList = () => api({ url: '/movie/list', method: 'get' });

//手机号验证码
export const reqMobileCode = (mobilePhone) => api({ url: "/user/mobile/code", headers: { 'content-type': 'application/x-www-form-urlencoded' }, data: { "mobilePhone": mobilePhone }, method: "post" });

//新用户注册
export const reqRegister = (data) => api({ url: "/user/register", headers: { 'content-type': 'application/json' }, data, method: "post" });

//用户登录
export const reqLogin = (data) => api({ url: "/user/login", headers: { 'content-type': 'application/json' }, data, method: "post" });

//搜索电影
export const reqGetSearchList = (movieName) => api({ url: `/movie/search/${movieName}`, method: "get" });

//电影详情
export const reqMovieDetail = (movieId) => api({ url: `/movie/detail/${movieId}`, method: "get" });
