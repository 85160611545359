import { reqMobileCode, reqRegister } from '@/api/index'
//home模块的小仓库
const state = {
  mobileCode: ""
};
const mutations = {
  MOBILECODE(state, mobileCode) {
    state.mobileCode = mobileCode;
  }
};
const actions = {
  //通过api里面的接口函数调用，向服务器发请求，获取服务器数据
  async mobileCode({ commit }, mobile) {
    let result = await reqMobileCode(mobile);
    if (result.code != 0) {
      console.log("验证码发送失败");
    }
  },
  async register({ commit }, data) {
    let result = await reqRegister(data);
    if (result.code != 0) {
      console.log("注册失败");
    }
  }
};
//计算属性
const getters = {};
export default {
  state, mutations, actions, getters
}