<template>
  <div class="content">
    <h2>热度排行榜</h2>
    <div class="ranking">
      <div class="center">
        <Carousel :list="showList" />
      </div>
    </div>
    <!-- 推荐电影卡片 -->
    <h2>今日份推荐</h2>
    <div class="movie-list">
      <div
        v-for="(movie, index) in movieList"
        :key="movie.id"
        class="movie-item"
      >
        <router-link :to="`/details/${movie.id}`">
          <img :src="movie.image" :alt="movie.name" />
          <h3>{{ movie.name }}</h3>
          <p>上映时间:{{ movie.releaseTime }}</p>
        </router-link>
      </div>
    </div>
    <!-- 用户留言功能 -->
    <div class="message-board">
      <div class="message-display" ref="messageDisplay">
        <div class="message-content">
          <!-- 显示留言 -->
          <div class="messages">
            <div
              v-for="(message, index) in messages"
              :key="message.id"
              class="message-item"
            >
              <div class="user-info">
                <img :src="message.userUrl" alt="我裂了" class="user-avatar" />
                <span class="message-time">{{ message.timestamp }}</span>
              </div>
              <p>{{ message.content }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="message-input">
        <h2>发表留言</h2>
        <form @submit.prevent="submitMessage">
          <textarea
            v-model="message"
            placeholder="在这里留下你的留言"
          ></textarea>
          <button type="submit">提交留言</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Carousel from "../../components/carousel ";
export default {
  name: "home",
  components: { Carousel },
  data() {
    return {
      showList: [
        {
          imgUrl:
            "https://wework.qpic.cn/wwpic/746649__nWnZUuQQGC9UMc_1686379321/0",
          intro: "4.3GB HD1080P + 12.5GB 4K 英语中英双字",
          name: "2023惊悚动作《速度与激情10》HD1080P&4K.英语中英双字",
          id: 1,
        },
        {
          imgUrl:
            "https://wework.qpic.cn/wwpic/36596_iMb1ldtkQZmq8MA_1684844508/0",
          intro: "7.9GB HD1080P + 25.3GB 4K 英语中英双字",
          name: "2023惊悚犯罪《疾速追杀4》HD1080P&4K.英语中英双字",
          id: 2,
        },
        {
          imgUrl:
            "https://wework.qpic.cn/wwpic/81531_xwVqPzFyQ2a0vx__1680875431/0",
          intro: "5.1GB HD1080P + 12.2GB 4K 英语中英双字",
          name: "2023奇幻冒险《雷霆沙赞！众神之怒》HD1080P&4K.英语中英双字",
          id: 3,
        },
        {
          imgUrl:
            "https://wework.qpic.cn/wwpic/169289_uWdAk2KeQpmpdUI_1672655395/0",
          intro: "9.3GB HD1080P 英语中英双字",
          name: "2022科幻冒险《阿凡达：水之道》HD1080P.英语中英双字",
          id: 4,
        },
        {
          imgUrl:
            "https://wework.qpic.cn/wwpic/767993_WOAGXdeVRgaQB2l_1668606817/0",
          intro: "4.8GB HD1080P 英语中英双字",
          name: "2022科幻动作《黑亚当》HD1080P.英语中英双字",
          id: 5,
        },
        {
          imgUrl:
            "https://wework.qpic.cn/wwpic/742352_pADn9rZ5RzSr-el_1660673554/0",
          intro: "3.6GB HD1080P 英语中英双字",
          name: "2022美国动作《壮志凌云2：独行侠》HD1080P.英语中英双字",
          id: 6,
        },
        {
          imgUrl:
            "https://wework.qpic.cn/wwpic/960851_Q1Ra1dMBRsuu3CX_1660192450/0",
          intro: "3.5GB HD1080P 韩语中字",
          name: "2022悬疑犯罪《分手的决心》HD1080P.韩语中字",
          id: 7,
        },
        {
          imgUrl:
            "https://wework.qpic.cn/wwpic/329289_TzG-vxTgSNSTm8__1657268756/0",
          intro: "3.49GB HD1080P 国语中字",
          name: "2022台湾恐怖《咒》HD1080P.国语中字",
          id: 8,
        },
        {
          imgUrl:
            "https://wework.qpic.cn/wwpic/983351_-tkZaMJOQS-sn6R_1655832294/0",
          intro: "2.6GB HD720P + 5GB HD1080P + 12.5GB 4K 英语中英双字",
          name: "2022奇幻冒险《奇异博士2：疯狂多元宇宙》HD720P&HD1080P&4K.英语中英双字",
          id: 9,
        },
        {
          imgUrl:
            "https://wework.qpic.cn/wwpic/313419_NgZYBGhoRQiR6FW_1652289651/0",
          intro:
            "5.9GB HD1080P 英语中英双字 美版的我感觉还没有韩版的清晰，只不过韩版的带韩文硬字幕而已，自己选择下载吧，在线资源就不更新美版的了。",
          name: "2022奇幻冒险《神奇动物：邓布利多之谜》HD1080P.英语中英双字",
          id: 10,
        },
      ],

      messages: [
        {
          id: 1,
          content: "第一条留言",
          timestamp: "2023-07-04 10:00:00",
          userUrl: "https://srb-syb.oss-cn-beijing.aliyuncs.com/FaZe/FaZe.jpg",
          userName: "Medusa",
        },
        {
          id: 2,
          content: "第二条留言",
          timestamp: "2023-07-04 11:00:00",
          userUrl: "https://srb-syb.oss-cn-beijing.aliyuncs.com/FaZe/FaZe.jpg",
          userName: "Medusa",
        },
        {
          id: 3,
          content: "第二条留言",
          timestamp: "2023-07-04 11:00:00",
          userUrl: "https://srb-syb.oss-cn-beijing.aliyuncs.com/FaZe/FaZe.jpg",
          userName: "Medusa",
        },
        {
          id: 4,
          content: "第二条留言",
          timestamp: "2023-07-04 11:00:00",
          userUrl: "https://srb-syb.oss-cn-beijing.aliyuncs.com/FaZe/FaZe.jpg",
          userName: "Medusa",
        },
        {
          id: 5,
          content: "第二条留言",
          timestamp: "2023-07-04 11:00:00",
          userUrl: "https://srb-syb.oss-cn-beijing.aliyuncs.com/FaZe/FaZe.jpg",
          userName: "Medusa",
        },
        {
          id: 6,
          content: "第二条留言",
          timestamp: "2023-07-04 11:00:00",
          userUrl: "https://srb-syb.oss-cn-beijing.aliyuncs.com/FaZe/FaZe.jpg",
          userName: "Medusa",
        },
        {
          id: 7,
          content: "第二条留言",
          timestamp: "2023-07-04 11:00:00",
          userUrl: "https://srb-syb.oss-cn-beijing.aliyuncs.com/FaZe/FaZe.jpg",
          userName: "Medusa",
        },
        // 其他留言数据...
      ],
      message: "",
    };
  },
  mounted() {
    this.$store.dispatch("movieList");
    this.startScroll();
  },
  methods: {
    startScroll() {
      const messageDisplay = this.$refs.messageDisplay;
      const messagesContainer = messageDisplay.querySelector(".messages");
      const messageHeight = messagesContainer.offsetHeight;
      const totalHeight = messageHeight * this.messages.length;

      let currentScrollTop = 0;
      setInterval(() => {
        if (currentScrollTop >= totalHeight) {
          currentScrollTop = 0;
        } else {
          currentScrollTop += messageHeight;
        }
        messagesContainer.style.transform = `translateY(-${currentScrollTop}px)`;
      }, 1000);
    },
    submitMessage() {
      const newMessage = {
        id: Date.now(),
        content: this.message,
        timestamp: new Date().toLocaleString(),
      };
      this.messages.push(newMessage);
      this.message = "";
    },
  },

  computed: {
    ...mapState({
      movieList: (state) => state.home.movieList,
    }),
  },
};
</script>

<style>
.content {
  background-color: #333;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ranking {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* 将 .center 垂直居中 */
}

Copy code .center {
  width: 1000px;
  height: 300px;
  background-color: black;
  overflow: hidden;
  display: flex;
  justify-content: center; /* 将 .Carousel 水平居中 */
}

.message-board {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #fff;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.message-board h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 10px;
}

.message-board form {
  margin-bottom: 10px;
}

.message-board textarea {
  width: 100%;
  height: 100px;
  resize: none;
  padding: 10px;
}

.message-board button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.message-board button:hover {
  background-color: #0056b3;
}

.messages {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation: scrollMessages 15s linear infinite;
  z-index: 0;
}

.message {
  background-color: #f9f9f9;
  padding: 10px;
}

.message p {
  margin-bottom: 5px;
}

.message span {
  color: #666;
  font-size: 12px;
}

.message-display {
  flex-grow: 1;
  height: 202px;
  overflow: hidden;
  position: relative;
}

.message-content {
  position: relative;
  z-index: 1;
}

.user-info {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.message-time {
  font-size: 12px;
  color: #666;
}

.message-input {
  height: 202px;
  flex-grow: 1;
}

.movie-list {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.movie-list {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.movie-item {
  flex-basis: calc(100% / 7); /* 让每个电影项占据一行的1/7宽度 */
  width: 166px;
  height: 221px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 25px;
}

.movie-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.movie-item h3 {
  font-size: 18px;
  margin: 10px 0;
}

.movie-item p {
  font-size: 14px;
  color: #666;
}

@keyframes scrollMessages {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
</style>
