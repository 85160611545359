import { reqGetSearchList } from "@/api";

//search模块的小仓库
const state = {
    searchResults: []
};
const mutations = {
    SEARCHMOVIELIST(state, searchResults) {
        state.searchResults = searchResults
    }
};
const actions = {
    async searchMovieList({ commit }, keyword) {
        let result = await reqGetSearchList(keyword);
        if (result.code == 0) {
            commit('SEARCHMOVIELIST', result.data)
        }
    }
};
//计算属性，在项目中，为了简化数据而生
const getters = {};
export default {
    state, mutations, actions, getters
}