import { reqMovieDetail } from '@/api/index'
//details模块的小仓库
const state = {
  movieDetail: {}
};
const mutations = {
  MOVIEDETAILSBYID(state, movieDetail) {
    state.movieDetail = movieDetail;
  }
};
const actions = {
  //通过api里面的接口函数调用，向服务器发请求，获取服务器数据
  async movieDetailsById({ commit }, movieId) {
    let result = await reqMovieDetail(movieId);
    if (result.code == 0) {
      commit('MOVIEDETAILSBYID', result.data)
    }
  }
};
//计算属性
const getters = {};
export default {
  state, mutations, actions, getters
}