<template>
  <div class="footer">
    <p>版权所有 &copy; 2023 电影资源分享</p>
    <p class="icp-info">ICP备案号：冀ICP备2021025288号</p>
    <p class="disclaimer">
      本站所有资源均来自互联网收集，也从未参与录制、上传，若本站收录的资源侵犯了您的权益，请一定告诉我们，本站在收到邮件并确认后的24小时内删除。
    </p>
    <p class="disclaimer">
      联系邮箱：<span>724435213syb@gmail.com</span>，广告合作也与该邮箱联系。
    </p>
  </div>
</template>

<script>
export default {
  name: "",
};
</script>

<style>
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 14px;
}

.icp-info {
  margin-top: 10px;
}

.disclaimer {
  margin-top: 10px;
  font-size: 12px;
}
</style>